<template>
    <v-dialog
        v-model="createOps.dialog"
        max-width="550px"
        persistent
        scrollable
    >
        <v-card flat>
            <v-card-title>
                <v-row>
                    <v-col cols="10">
                        <span>Create Tracker</span>
                    </v-col>
                    <v-col cols="2">
                        <div class="float-right">
                            <CustomButton
                                btnIcon="mdi-close"
                                tooltipMsg="Close"
                                @click="hideDialog"
                            />
                        </div>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-divider />

            <v-card-text class="px-8">
                <ValidationObserver ref="observer" v-slot="{}">
                    <v-form>
                        <v-row dense>
                            <v-col cols="12" sm="6">
                                <v-card flat max-height="110" min-height="110">
                                    <v-card-text>
                                        <v-row>
                                            <h3>Current Session:</h3>
                                        </v-row>

                                        <v-row>
                                            <v-col class="d-flex justify-start">
                                                <span class="avsb-timer-values">
                                                    {{ duration.display.hours }}
                                                </span>
                                            </v-col>

                                            <v-col class="d-flex justify-start">
                                                <span class="avsb-timer-values">
                                                    {{
                                                        duration.display.minutes
                                                    }}
                                                </span>
                                            </v-col>

                                            <v-col class="d-flex justify-start">
                                                <span class="avsb-timer-values">
                                                    {{
                                                        duration.display.seconds
                                                    }}
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row dense>
                                            <v-col class="d-flex justify-start">
                                                <span
                                                    class="
                                                        avsb-timer-indicators
                                                    "
                                                    >Hours</span
                                                >
                                            </v-col>
                                            <v-col class="d-flex justify-start"
                                                ><span
                                                    class="
                                                        avsb-timer-indicators
                                                    "
                                                    >Minutes</span
                                                ></v-col
                                            >
                                            <v-col class="d-flex justify-start"
                                                ><span
                                                    class="
                                                        avsb-timer-indicators
                                                    "
                                                    >Seconds</span
                                                ></v-col
                                            >
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>

                            <v-col cols="12" sm="6">
                                <v-card flat max-height="110" min-height="110">
                                    <v-card-text>
                                        <v-row align="start">
                                            <v-col>
                                                <CustomSwitch
                                                    id="tracker_toggle"
                                                    :switchLabel="`Status: ${
                                                        status
                                                            ? 'Online'
                                                            : 'Offline'
                                                    }`"
                                                    :switchValue="status"
                                                    @change="toggleStatus"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>

                        <!-- Start date and time region -->
                        <v-row dense>
                            <v-col cols="12" sm="6">
                                <CustomLabel
                                    for="start_date"
                                    label="Start Date"
                                    required
                                />
                                <CustomTextField
                                    id="start_date"
                                    inputIcon="mdi-calendar"
                                    inputPlaceholder="YYYY-MM-DD"
                                    :inputValue="start_date"
                                    validationName="start_date"
                                    validationRules="required"
                                    @change="
                                        updateLocalForm($event, 'start_date')
                                    "
                                    @click:prepend-inner="showIconDialog"
                                    @input="
                                        updateLocalForm($event, 'start_date')
                                    "
                                />
                                <!-- <CustomDatePickerField
                                            id="start_date"
                                            inputPlaceholder="YYYY-MM-DD"
                                            :inputValue="start_date"
                                            validationName="start_date"
                                            validationRules="required"
                                            @change="
                                                updateLocalForm(
                                                    $event,
                                                    'start_date'
                                                )
                                            "
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'start_date'
                                                )
                                            "
                                        /> -->
                            </v-col>

                            <v-col cols="12" sm="6">
                                <CustomLabel
                                    for="start_time"
                                    label="Start Time"
                                    required
                                />
                                <CustomTextField
                                    id="start_time"
                                    inputIcon="mdi-clock"
                                    inputPlaceholder="HH:MM"
                                    :inputValue="start_time"
                                    validationName="start_time"
                                    validationRules="required"
                                    @click:prepend-inner="showIconDialog"
                                    @input="
                                        updateLocalForm($event, 'start_time')
                                    "
                                />
                                <!-- <CustomTimePickerField
                                            id="start_time"
                                            inputPlaceholder="HH:MM"
                                            :inputValue="start_time"
                                            validationName="start_time"
                                            validationRules="required"
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'start_time'
                                                )
                                            "
                                        /> -->
                            </v-col>
                        </v-row>

                        <!-- End date and time region -->
                        <v-row dense>
                            <v-col cols="12" sm="6">
                                <CustomLabel
                                    for="end_date"
                                    label="End Date"
                                    required
                                />
                                <CustomTextField
                                    id="end_date"
                                    inputIcon="mdi-calendar"
                                    inputPlaceholder="YYYY-MM-DD"
                                    :inputValue="end_date"
                                    validationName="end_date"
                                    validationRules="required|beyondStartDate:@start_date"
                                    @change="
                                        updateLocalForm($event, 'end_date')
                                    "
                                    @click:prepend-inner="showIconDialog"
                                    @input="updateLocalForm($event, 'end_date')"
                                />
                                <!-- <CustomDatePickerField
                                            id="end_date"
                                            inputPlaceholder="YYYY-MM-DD"
                                            :inputValue="end_date"
                                            validationName="end_date"
                                            validationRules="required|beyondStartDate:@start_date"
                                            @change="
                                                updateLocalForm(
                                                    $event,
                                                    'end_date'
                                                )
                                            "
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'end_date'
                                                )
                                            "
                                        /> -->
                            </v-col>

                            <v-col cols="12" sm="6">
                                <CustomLabel
                                    for="end_time"
                                    label="End Time"
                                    required
                                />
                                <CustomTextField
                                    id="end_time"
                                    inputIcon="mdi-clock"
                                    inputPlaceholder="HH:MM"
                                    :inputValue="end_time"
                                    validationName="end_time"
                                    validationRules="required|beyondStartTime:@start_date,@start_time,@end_date|minimumBlockSize:@start_date,@start_time,@end_date"
                                    @click:prepend-inner="showIconDialog"
                                    @input="updateLocalForm($event, 'end_time')"
                                />
                                <!-- <CustomTimePickerField
                                            id="end_time"
                                            inputPlaceholder="HH:MM"
                                            :inputValue="end_time"
                                            validationName="end_time"
                                            validationRules="required|beyondStartTime:@start_date,@start_time,@end_date|minimumBlockSize:@start_date,@start_time,@end_date"
                                            @input="
                                                updateLocalForm(
                                                    $event,
                                                    'end_time'
                                                )
                                            "
                                        /> -->
                            </v-col>
                        </v-row>

                        <!-- Category region -->
                        <v-row dense>
                            <v-col>
                                <CustomLabel
                                    for="category"
                                    label="Category"
                                    required
                                />
                                <CustomAutoCompleteField
                                    id="category"
                                    inputPlaceholder="Category"
                                    :inputValue="createPayload.category_id"
                                    validationName="name"
                                    validationRules="required"
                                    :optionItems="categories"
                                    optionText="name"
                                    optionValue="id"
                                    @change="
                                        handleAutoCompleteChange(
                                            'createPayload',
                                            'category_id',
                                            'id',
                                            $event
                                        )
                                    "
                                    @intersect="
                                        retrieveCategoryItems(
                                            'name',
                                            retrieveCategoryOps
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>

                        <!-- Customer region -->
                        <v-row dense>
                            <v-col>
                                <CustomLabel
                                    for="customer"
                                    label="Customer"
                                    required
                                />
                                <CustomAutoCompleteField
                                    id="customer"
                                    inputPlaceholder="Customer"
                                    :inputValue="createPayload.customer_id"
                                    :optionItems="customers"
                                    optionText="name"
                                    optionValue="id"
                                    validationName="customer"
                                    validationRules="required"
                                    @change="
                                        appendLocalClients(
                                            'createPayload',
                                            'customer_id',
                                            'id',
                                            $event
                                        )
                                    "
                                    @intersect="
                                        retrieveCustomerItems(
                                            'name',
                                            retrieveCustomerOps
                                        )
                                    "
                                >
                                    <template v-slot:prepend-inner="{}">
                                        <span> Hello World </span>
                                    </template>
                                </CustomAutoCompleteField>
                            </v-col>
                        </v-row>

                        <!-- Client region -->
                        <v-row dense>
                            <v-col>
                                <CustomLabel for="client" label="Client" />
                                <CustomAutoCompleteField
                                    id="client"
                                    inputPlaceholder="Client"
                                    :inputValue="createPayload.client_id"
                                    :optionItems="clients"
                                    optionText="name"
                                    optionValue="id"
                                    @change="
                                        handleAutoCompleteChange(
                                            'createPayload',
                                            'client_id',
                                            'id',
                                            $event
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>

                        <!-- Task region -->
                        <v-row dense>
                            <v-col>
                                <CustomLabel for="task" label="Task" required />
                                <CustomAutoCompleteField
                                    id="task"
                                    inputIsClearable
                                    inputPlaceholder="Task"
                                    :inputValue="createPayload.task_id"
                                    :optionItems="tasks"
                                    optionText="name"
                                    optionValue="id"
                                    validationName="task"
                                    validationRules="required"
                                    @change="
                                        handleAutoCompleteChange(
                                            'createPayload',
                                            'task_id',
                                            'id',
                                            $event
                                        )
                                    "
                                    @click:clear="
                                        handleAutoCompleteChange(
                                            'createPayload',
                                            'task_id',
                                            'id',
                                            $event
                                        )
                                    "
                                    @intersect="
                                        retrieveTaskItems(
                                            'name',
                                            retrieveTaskOps
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>

                        <!-- Description region -->
                        <v-row dense>
                            <v-col>
                                <CustomLabel
                                    for="description"
                                    label="Description"
                                />
                                <CustomTextAreaField
                                    id="description"
                                    inputPlaceholder="Description"
                                    :inputValue="createPayload.description"
                                    @input="
                                        updateForm(
                                            $event,
                                            'createPayload.description'
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>

                        <v-row dense>
                            <v-col>
                                <CustomCheckBox
                                    inputLabel="Include tracker event in Google Calendar*"
                                    inputValue
                                    @change="
                                        updateForm(
                                            $event,
                                            'createPayload.integration'
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>

                        <!-- Action button region -->
                        <v-row class="mt-4">
                            <v-col>
                                <CustomButton
                                    btnLabel="Cancel"
                                    tooltipMsg="Cancel"
                                    @click="hideDialog"
                                />
                                <CustomButton
                                    btnColour="primary"
                                    :btnIsLoading="createOps.isLoading"
                                    btnLabel="Save"
                                    tooltipMsg="Save"
                                    @click="
                                        createItems(
                                            createPayload,
                                            start_date,
                                            end_date,
                                            start_time,
                                            end_time
                                        )
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </ValidationObserver>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { DateTime } from "luxon";
import { mapState } from "vuex";
import Timer from "easytimer.js";
import { extend } from "vee-validate";
import { setPath } from "@/services/helpers";

var timer = new Timer();

extend("beyondStartDate", {
    params: ["startDate"],
    validate(value, { startDate }) {
        return DateTime.fromSQL(value) >= DateTime.fromSQL(startDate);
    },
    message: "End date cannot be earlier than start date.",
});

extend("beyondStartTime", {
    params: ["startDate", "startTime", "endDate"],
    validate(value, { startDate, startTime, endDate }) {
        return (
            DateTime.fromSQL(`${startDate} ${startTime}`) <
            DateTime.fromSQL(`${endDate} ${value}`)
        );
    },
    message: "End time cannot be earlier or equivalent to start time.",
});

extend("minimumBlockSize", {
    params: ["startDate", "startTime", "endDate", "endTime"],
    validate(value, { startDate, startTime, endDate }) {
        let start = DateTime.fromSQL(`${startDate} ${startTime}`);
        let end = DateTime.fromSQL(`${endDate} ${value}`);

        let difference = end.diff(start).toObject();
        let seconds = difference.milliseconds / 1000;
        let minutes = seconds / 60;

        let remainder = minutes % 10;

        return remainder === 0;
    },
    message: "Total time not in 10 minute blocks.",
});

export default {
    computed: {
        ...mapState("trackersUserMgmt", ["createOps", "createPayload"]),
        ...mapState("customersUserMgmt", ["customers", "retrieveCustomerOps"]),
        ...mapState("categoriesUserMgmt", [
            "categories",
            "retrieveCategoryOps",
        ]),
        ...mapState("tasksUserMgmt", ["tasks", "retrieveTaskOps"]),
    },
    data: () => ({
        tabs: ["create"],
        duration: {
            display: {
                hours: "00",
                minutes: "00",
                seconds: "00",
            },
        },
        integration: true,
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        clients: [],
        status: false,
    }),
    methods: {
        setPath,
        hideDialog() {
            this.$store.commit("trackersUserMgmt/HIDE_CREATE_OPS_DIALOG");
            this.$store.commit("trackersUserMgmt/FLUSH_FIELD", "createPayload");
            this.$refs.observer.reset();
        },
        showIconDialog(event) {
            console.log(event);
            console.log("Show icon dialog.");
        },
        async createItems(payload, start_date, end_date, start_time, end_time) {
            let valid = await this.$refs.observer.validate();

            if (valid) {
                let rawDateTimeStart = `${start_date} ${start_time}`;
                let rawDateTimeEnd = `${end_date} ${end_time}`;
                let datetime_start = DateTime.fromFormat(
                    rawDateTimeStart,
                    "yyyy-LL-dd HH:mm"
                );
                let datetime_end = DateTime.fromFormat(
                    rawDateTimeEnd,
                    "yyyy-LL-dd HH:mm"
                );

                let response = await this.$store.dispatch(
                    "trackersUserMgmt/createItems",
                    {
                        datetime_start: datetime_start.setZone("UTC").toISO(),
                        datetime_end: datetime_end.setZone("UTC").toISO(),
                        category_id: payload.category_id,
                        customer_id: payload.customer_id,
                        client_id: payload.client_id,
                        task_id: payload.task_id,
                        description: payload.description,
                    }
                );

                if (response) {
                    this.flushStorage();
                    this.$refs.observer.reset();
                }
            }

            // this.flushStorage();
        },
        updateForm(event, form) {
            if (event instanceof InputEvent) {
                this.$store.commit("trackersUserMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event.target.value,
                    },
                });
                return;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this.$store.commit("trackersUserMgmt/UPDATE_FORM", {
                    payload: {
                        form: form,
                        value: event,
                    },
                });
                return;
            } else {
                return;
            }
        },
        updateLocalForm(event, form) {
            if (event instanceof InputEvent) {
                this[form] = event.target.value;
            } else if (
                event instanceof String ||
                typeof event == "boolean" ||
                typeof event == "number" ||
                typeof event == "string"
            ) {
                this[form] = event;
            }
        },
        handleAutoCompleteChange(form, name, property, item) {
            let payload = {
                form: form,
                name: name,
                property: property,
                item: item,
            };
            this.$store.commit("trackersUserMgmt/UPDATE_ITEM_FORM", {
                payload,
            });
        },
        handleDateTimeInput(form, name, value) {
            let payload = {
                form: form,
                name: name,
                value: value,
            };
            this.$store.commit("trackersUserMgmt/UPDATE_VALUE_FORM", {
                payload,
            });
        },
        handleDateTimeInputLocal(name, value) {
            this[name] = value;
        },
        appendLocalClients(form, name, property, item) {
            this.handleAutoCompleteChange(form, name, property, item);
            this.$store.commit("trackersUserMgmt/UPDATE_VALUE_FORM", {
                payload: {
                    form: "createPayload",
                    name: "client_id",
                    value: null,
                },
            });

            let selectedCustomer = this.customers.find(
                (element) => element.id == item.id
            );

            this.clients = [];
            selectedCustomer.clients.forEach((item) => {
                this.clients.push(item);
            });
        },
        startTimer() {
            let startDateTime = DateTime.local();
            localStorage.setItem("clockInDateTime", startDateTime.toISO());

            this.start_date = startDateTime.toFormat("yyyy-LL-dd");
            this.start_time = startDateTime.toFormat("HH:mm");

            this.$store.commit("trackersUserMgmt/UPDATE_VALUE_FORM", {
                payload: {
                    form: "createPayload",
                    name: "datetime_start",
                    value: startDateTime.toISO(),
                },
            });

            timer.start();
            timer.addEventListener("secondsUpdated", (event) => {
                let duration = event.detail.timer.getTimeValues();

                this.duration.display.hours = duration.hours
                    .toString()
                    .padStart(2, "0");
                this.duration.display.minutes = duration.minutes
                    .toString()
                    .padStart(2, "0");
                this.duration.display.seconds = duration.seconds
                    .toString()
                    .padStart(2, "0");
            });
        },
        stopTimer() {
            let endDateTime = DateTime.local();
            localStorage.setItem("clockOutDateTime", endDateTime.toISO());

            this.end_date = endDateTime.toFormat("yyyy-LL-dd");
            this.end_time = endDateTime.toFormat("HH:mm");

            this.$store.commit("trackersUserMgmt/UPDATE_VALUE_FORM", {
                payload: {
                    form: "createPayload",
                    name: "datetime_end",
                    value: endDateTime.toISO(),
                },
            });

            timer.stop();
        },
        retrieveCategoryItems(order_by, meta) {
            let options = { order_by, ...meta };
            this.$store.dispatch("categoriesUserMgmt/retrieveItems", options);
        },
        retrieveCustomerItems(order_by, meta) {
            let options = { order_by, ...meta };
            this.$store.dispatch("customersUserMgmt/retrieveItems", options);
        },
        retrieveTaskItems(order_by, meta) {
            let options = { order_by, ...meta };
            this.$store.dispatch("tasksUserMgmt/retrieveItems", options);
        },
        rePopulateDateTimeFields() {
            if (localStorage.getItem("clockInDateTime") != null) {
                this.start_date = DateTime.fromISO(
                    localStorage.getItem("clockInDateTime")
                ).toFormat("yyyy-LL-dd");
                this.start_time = DateTime.fromISO(
                    localStorage.getItem("clockInDateTime")
                ).toFormat("HH:mm");
            }

            if (localStorage.getItem("clockOutDateTime") != null) {
                this.end_date = DateTime.fromISO(
                    localStorage.getItem("clockOutDateTime")
                ).toFormat("yyyy-LL-dd");
                this.end_time = DateTime.fromISO(
                    localStorage.getItem("clockOutDateTime")
                ).toFormat("HH:mm");
            }
        },
        toggleStatus(value) {
            if (value) {
                this.status = value;
                this.duration.display.hours = 0;
                this.duration.display.minutes = 0;
                this.duration.display.seconds = 0;
                this.startTimer();
            } else {
                this.status = value;
                this.stopTimer();
            }
        },
        flushStorage() {
            localStorage.removeItem("clockInDateTime");
            localStorage.removeItem("clockOutDateTime");
            this.start_date = null;
            this.start_time = null;
            this.end_date = null;
            this.end_time = null;
        },
    },
    name: "DialogTrackerCreate",
    watch: {},
    // Lifecycle hooks.
    beforeCreate() {},
    created() {
        this.rePopulateDateTimeFields();
    },
    beforeMount() {
        this.retrieveCategoryItems("name", this.retrieveCategoryOps);
        this.retrieveCustomerItems("name", this.retrieveCustomerOps);
        this.retrieveTaskItems("name", this.retrieveTaskOps);
    },
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>